<template>
  <div class="packaging_operation">
    <div class="operation_box">
      <!--扫描-->
      <Card :bordered="false" class="card_box" dis-hover>
        <template #title>
          <div class="title_box">
            <h3 class="title">{{ $t('key1005575') }}</h3>
            <div class="options">
              <!--校对SKU-->
              <div class="options_item">
                <span class="txt">{{ $t('key1006004') }}</span>
                <Icon :class="packagingSetupForm.checkSku === 'Y' ? 'icon_yes' : 'icon_no'"
                  :type="packagingSetupForm.checkSku === 'Y' ? 'md-checkmark' : 'md-close'"/>
              </div>
              <!--校对身份码-->
              <div class="options_item">
                <span class="txt">{{ $t('key1005785') }}</span>
                <Icon :class="packagingSetupForm.mobileImie === 'Y' ? 'icon_yes' : 'icon_no'"
                  :type="packagingSetupForm.mobileImie === 'Y' ? 'md-checkmark' : 'md-close'"/>
              </div>
              <!--称重-->
              <div class="options_item">
                <span class="txt">{{ $t('key1003109') }}</span>
                <Icon :class="packagingSetupForm.packingEnableWeight === 'Y' ? 'icon_yes' : 'icon_no'"
                  :type="packagingSetupForm.packingEnableWeight === 'Y' ? 'md-checkmark' : 'md-close'"/>
              </div>
              <!--称重单位-->
              <div class="options_item">
                <span class="txt">{{ weightTitle }}</span>
                <Tooltip max-width="200" :content="$t('key1003107')">
                  <Icon class="help_icon" type="md-help-circle"></Icon>
                </Tooltip>
              </div>
              <!--设置-->
              <div class="options_item">
                <Icon class="icon_set" type="md-settings" @click="packagingSetup = true"/>
              </div>
            </div>
          </div>
        </template>

        <Form :model="formParameter" ref="formParameter" class="form_box" :label-width="140" @submit.native.prevent>
          <!--识别号-->
          <FormItem :label="$t('key1005996')" prop="identificationNumber">
            <Input v-model.trim="formParameter.identificationNumber"
              :element-id="'identificationNumber'"
              style="width:300px"
              clearable
              :placeholder="$t('key1005810')"
              @on-keyup.13="identificationKey(1)"></Input>
            <Checkbox @on-change="saveLocalData" class="inline-block ml10" v-model="isSliceIdentificationNumber">{{ $t('key1005821') }}</Checkbox>
            {{ $t('key1003230') }}
            <InputNumber v-model="sliceStartLength" @on-change="saveLocalData" :disabled="!isSliceIdentificationNumber"
              :precision=0 style="height: 24px; width: 50px;" :min=0 :max=999999 size="small"/>
            {{ $t('key1003231') }}
            <InputNumber :disabled="!isSliceIdentificationNumber" @on-change="saveLocalData" :precision=0
              style="height: 24px; width: 50px;" size="small" :min=0 :max=999999 v-model="sliceEndLength"/>
            <Tooltip :content="$t('key1005811')">
              <Icon type="md-help-circle" size="18" class="cursor ml5"/>
            </Tooltip>
          </FormItem>
          <!--扫描SKU/货品身份码-->
          <FormItem :label="$t('key1005997')" v-if="packagingSetupForm.checkSku === 'Y'" prop="scanSku">
            <Input v-model.trim="formParameter.scanSku"
              style="width:300px"
              clearable
              :element-id="'scanSku'"
              :placeholder="$t('key1005998')"
              @on-keyup.13="identificationKey(2)"></Input>
          </FormItem>
          <!--扫描身份码-->
          <FormItem :label="$t('key1005999')" v-if="packagingSetupForm.mobileImie === 'Y'" prop="mobileImie">
            <Input v-model.trim="formParameter.mobileImie"
              style="width:300px"
              :element-id="'mobileImie'"
              :placeholder="$t('key1006000')"
              clearable
              @on-keyup.13="identificationKey(5)"></Input>
          </FormItem>
          <!--称重-->
          <FormItem :label="$t('key1003106')" prop="weight" v-if="packagingSetupForm.packingEnableWeight === 'Y'">
            <Input v-model.trim="formParameter.weight"
              type="number"
              style="width:300px"
              :element-id="'weight'"
              number
              :maxlength="15"
              @on-keyup.13="identificationKey(4)">
              <template #append>
                {{ packagingSetupForm.packingWeightUnit }}
              </template>
            </Input>
          </FormItem>
        </Form>
      </Card>
      <!--出库单-->
      <Card class="outbound_order" v-if="outboundOrderData.length >0" dis-hover>
        <template #title>
          <div class="outbound_tiem">
            <h2 class="title">{{ $t('key1001490') + deliveryOrder }}</h2>
            <Button type="primary" @click="markExceptionBtn" v-if="getPermission('wmsPicking_markException')">{{ $t('key1006001') }}
            </Button>
          </div>
        </template>
        <Table highlight-row border max-height='650' :columns="outboundOrderColumn" :data="outboundOrderData"></Table>
      </Card>
    </div>
    <!--已扫描包裹-->
    <div class="table_box">
      <h2 class="title">{{ $t('key1006005') }}</h2>
      <Table highlight-row border max-height='700' :columns="packageColumn" :data="packageData"></Table>
    </div>
    <!--标记异常-->
    <Modal v-model="markException"
      class-name="markExceptionModal"
      :title="$t('key1006001')"
      width="700"
      :mask-closable="false"
      @on-visible-change="packagingSetupChange">
      <div class="markException_box">
        <h2 class="title">{{ $t('key1002525') + deliveryOrder + $t('key1006003') }}</h2>
        <Table highlight-row border :columns="markExceptionColumn" :data="markExceptionData"></Table>
      </div>
      <template #footer>
        <div style="text-align: center;">
          <Button @click="markException = false">{{ $t('key1000097') }}</Button>
          <Button type="primary" @click="markExceptionSubmit">{{ $t('key1000096') }}</Button>
        </div>
      </template>
    </Modal>
    <!--包装设置-->
    <Modal v-model="packagingSetup"
      class-name="packagingSetupModal"
      :title="$t('key1006002')"
      width="700"
      :mask-closable="false"
      @on-visible-change="packagingSetupChange">
      <Form ref="packagingSetupForm" :model="packagingSetupForm" @submit.native.prevent>
        <h2 class="modelTit">{{ $t('key1003223') }}</h2>
        <FormItem>
          <Checkbox v-model="packagingSetupForm.checkSku"
            :true-value="'Y'"
            :false-value="'N'"
            @on-change="changeSku">{{ $t('key1006004') }}
          </Checkbox>
        </FormItem>
        <FormItem>
          <div style="display: flex; align-items: center;">
            <Checkbox v-model="packagingSetupForm.packingEnableWeight" :true-value="'Y'" :false-value="'N'">{{ $t('key1005825') }}
            </Checkbox>
            <Select v-model="packagingSetupForm.packingWeightUnit"
              size="small"
              filterable
              style="width: 150px; margin-left: 20px;">
              <Option v-for="(item, index) in weightTypeList" :key="index" :value="item.value"> {{ item.label }}
              </Option>
            </Select>
          </div>
        </FormItem>
        <FormItem>
          <Checkbox v-model="packagingSetupForm.mobileImie"
            :true-value="'Y'"
            :false-value="'N'"
            @on-change="changeMobileImie">{{ $t('key1005785') }}
          </Checkbox>
        </FormItem>
        <h2 class="modelTit" style="margin-top: 30px">{{ $t('key1005827') }} </h2>
        <p>{{ $t('key1005828') }}</p>
        <FormItem>
          <Checkbox class="inline-block"
            v-model="packagingSetupForm.packingSingleWeightRemark"
            :true-value="'Y'"
            :false-value="'N'">{{ $t('key1000179') }}
          </Checkbox>
          <span>{{ $t('key1005829') }}</span>
          <RadioGroup v-model="disabledGroup">
            <Radio label="0">
              <Input style="width: 60px" clearable v-model.trim="packagingSetupForm.packingSingleTranscend"/>
              {{ $t('key1003127') }}
            </Radio>
            <Radio label="1">
              <Input style="width: 60px" clearable v-model.trim="packagingSetupForm.packingSingleTranscendPercent"/>
              %
            </Radio>
          </RadioGroup>
          <span>{{ $t('key1005830') }}</span>
        </FormItem>
        <FormItem>
          <Checkbox class="inline-block"
            v-model="packagingSetupForm.packingMultiWeightRemark"
            :true-value="'Y'"
            :false-value="'N'">{{ $t('key1000178') }}
          </Checkbox>
          <span>{{ $t('key1005829') }}</span>
          <RadioGroup v-model="disabled1Group">
            <Radio label="0">
              <Input style="width: 60px" clearable v-model.trim="packagingSetupForm.packingMultiTranscend"/>
              {{ $t('key1003127') }}
            </Radio>
            <Radio label="1">
              <Input style="width: 60px" clearable v-model.trim="packagingSetupForm.packingMultiTranscendPercent"/>
              %
            </Radio>
          </RadioGroup>
          <span>{{ $t('key1005830') }}</span>
        </FormItem>
      </Form>
      <template #footer>
        <div style="text-align: center;">
          <Button @click="packagingSetup = false">{{ $t('key1000204') }}</Button>
          <Button type="primary"
            @click="packagingSetupBtn"
            v-if="getPermission('packageSettingCommon_saveDeliverySetting')">{{ $t('key1000374') }}
          </Button>
        </div>
      </template>
    </Modal>
    <!--称重异常提醒-->
    <Modal v-model="abnormalWeighing"
      :title="$t('key1005813')"
      width="600"
      :closable="false"
      class-name="abnormalWeighingModal"
      :mask-closable="false">
      <Alert type="warning" show-icon>{{ abnormalReminder.error }}</Alert>
      <Table :columns="abnormalWeighingColumns" :data="abnormalWeighingData" max-height="400"></Table>
      <div class="product_weight_box">
        <p class="line_txt">
          <span>{{ $t('key1005833') }}</span>
          <span class="number">{{ abnormalReminder.productWeight }}</span>
        </p>
      </div>
      <template #footer>
        <div style="text-align: center; padding: 10px 0 20px 0;">
          <Button @click="unpackingBtn">{{ $t('key1006006') }}</Button>
          <Button type="primary" @click="continueDeliverGoods">{{ $t('key1006007') }}</Button>
        </div>
      </template>
    </Modal>
    <!--订单取消提示-->
    <orderCancelPromptModal ref="orderCancelPrompt" :btnStatus="true"></orderCancelPromptModal>
  </div>
</template>

<script>
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';

import api from '@/api/';
import Mixin from '@/components/mixin/common_mixin';
import orderCancelPromptModal from "@/components/common/orderCancelPromptModal";

export default {
  name: 'packagingOperation',
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      isSliceIdentificationNumber: false,
      sliceStartLength: 0,
      sliceEndLength: 0,
      formParameter: {
        identificationNumber: '',
        scanSku: '',
        mobileImie: '',
        weight: ''
      },
      signSku: '',
      packageData: [],
      packageColumn: [
        {
          title: 'NO',
          align: 'center',
          width: 100,
          key: 'packageNo',
          type: 'index',
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000916'),
          align: 'center',
          key: 'packageCode'
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002837'),
          align: 'center',
          key: 'trackingNumber'
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003840'),
          align: 'center',
          key: 'skuNumber',
          render: (h, params) => {
            let data = params.row.skuList;
            let talg = [];
            if (data.length > 0) {
              data.map((item) => {
                talg.push(h('p', {
                  style: {
                    lineHeight: '25px'
                  }
                }, item.sku + ' * ' + item.quantity));
              });
            }
            return h('div', talg);
          }
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006008'),
          align: 'center',
          key: 'title'
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000711'),
          align: 'center',
          key: 'weight'
        }
      ],
      outboundOrderData: [],
      outboundOrderColumn: [
        {
          title: 'NO',
          width: 60,
          align: 'center',
          key: 'outboundOrderNo',
          type: 'index',
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003852'),
          align: 'center',
          key: 'title'
        },
        {
          title: 'SKU',
          align: 'center',
          key: 'sku'
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006009'),
          align: 'center',
          key: 'quantity'
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003323'),
          align: 'center',
          key: 'scannedQuantity',
          render: (h, params) => {
            return h('InputNumber', {
              props: {
                size: 'small',
                min: 0,
                max: params.row.quantity,
                value: params.row.scannedQuantity
              },
              on: {
                input: val => {
                  v.outboundOrderData[params.index].scannedQuantity = val;
                  v.$nextTick(() => {
                    if (v.packagingSetupForm.checkSku === 'Y') {
                      if (params.row.scannedQuantity === params.row.quantity) {
                        v.handerCheckSku();
                        if (v.packagingSetupForm.packingEnableWeight === 'Y') {
                          v.$nextTick(() => {
                            v.getFocus('weight');
                          });
                        }
                      }
                    }
                  });
                }
              }
            });
          }
        }
      ],
      packagingSetup: false,
      packagingSetupForm: {
        mobileImie: 'Y', // 是否校验身份码  N 不需要 Y 需要
        packingEnableWeight: 'Y', // 是否需要称重 N 不需要 Y 需要*/
        packingWeightUnit: 'g', //  g , kg
        checkSku: 'Y', // 是否校对SKU Y 是 N 否
        packingSingleWeightRemark: 'Y', // 单品称重异常提醒 Y是 N否
        packingSingleTranscend: '', // 单品称重误差阀值 单位g
        packingSingleTranscendPercent: null, // 单品称重误差百分比
        packingMultiWeightRemark: 'Y', // 多品称重异常提醒 Y是 N否
        packingMultiTranscend: '', // 多品称重误差阀值 单位g
        packingMultiTranscendPercent: null // 多品称重误差百分比
      },
      weightTypeList: [
        {
          label: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006010'),
          value: 'g'
        }, {
          label: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006011'),
          value: 'kg'
        }
      ],
      disabledGroup: '0',
      disabled1Group: '0',
      markException: false,
      markExceptionData: [],
      markExceptionColumn: [
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000160'),
          align: 'center',
          key: 'img',
          minWidth: 120,
          render(h, params) {
            return v.tableImg(h, params, 'pictureUrl');
          }
        },
        {
          title: 'SKU',
          align: 'center',
          key: 'sku'
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006012'),
          align: 'center',
          key: 'quantity'
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003323'),
          align: 'center',
          key: 'scannedQuantity',
          render: (h, params) => {
            return h('InputNumber', {
              props: {
                size: 'small',
                min: 0,
                max: params.row.quantity,
                value: params.row.scannedQuantity
              },
              on: {
                input: val => {
                  v.markExceptionData[params.index].scannedQuantity = val;
                }
              }
            });
          }
        }
      ],
      abnormalWeighing: false,
      abnormalWeighingData: [],
      abnormalWeighingColumns: [
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000160'),
          align: 'center',
          minWidth: 120,
          render(h, params) {
            return v.tableImg(h, params, 'pictureUrl');
          }
        },
        {
          title: 'SKU',
          align: 'center',
          key: 'sku'
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006013'),
          align: 'center',
          key: 'productWeight'
        }
      ],
      deliveryOrder: '',
      packageId: null,
      abnormalReminder: {},
      originalData: {},
      weightTitle: ''
    };
  },
  created() {
    let v = this;
    v.getDeliverySetting();
    v.$nextTick(() => {
      v.getFocus('identificationNumber');
    });
    let scanSliceNumber = localStorage.getItem('scanSliceNumber');
    if (scanSliceNumber) {
      let data = JSON.parse(scanSliceNumber);
      v.isSliceIdentificationNumber = data.isSliceIdentificationNumber;
      v.sliceStartLength = data.sliceStartLength;
      v.sliceEndLength = data.sliceEndLength;
    }
  },
  methods: {
    saveLocalData() {
      localStorage.setItem('scanSliceNumber', JSON.stringify({
        isSliceIdentificationNumber: this.isSliceIdentificationNumber,
        sliceStartLength: this.sliceStartLength,
        sliceEndLength: this.sliceEndLength
      }));
    },
    // 扫描最后调用的公共方法
    handerData(type) {
      let v = this;
      let data = [];
      if (v.outboundOrderData.length > 0) {
        for (let i = 0; i < v.outboundOrderData.length; i++) {
          // 当校对SKU时，先判断当前包裹中的sku是否已经全部扫描完，只有当扫描完成时，才能完成扫描包裹
          // 如果没有设置校对SKU，则不需要判断
          if (v.packagingSetupForm.checkSku === 'Y') {
            if (v.outboundOrderData[i].quantity !== v.outboundOrderData[i].scannedQuantity) {
              v.$Message.error({
                duration: 5,
                content: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006014')
              });
              return false;
            }
          }
          v.outboundOrderData[i].skuLists = [];
          if (v.packagingSetupForm.mobileImie === 'Y') {
            let mobileImieArr = v.outboundOrderData[i].mobileImieList;
            if (mobileImieArr.length > 0) {
              for (let j = 0; j < mobileImieArr.length; j++) {
                if (mobileImieArr[j].sku === v.outboundOrderData[i].sku) {
                  if (mobileImieArr[j].value !== '') {
                    v.outboundOrderData[i].skuLists.push(mobileImieArr[j].value);
                  }
                }
              }
              // 校验身份码是否全部填写或者全部都没有填写
              if (v.outboundOrderData[i].skuLists.length > 0 && v.outboundOrderData[i].skuLists.length !== mobileImieArr.length) {
                v.$Message.error({
                  duration: 5,
                  content: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006015')
                });
                return false;
              }
            }
          }
          let obj = {
            sku: v.outboundOrderData[i].sku,
            quantity: v.packagingSetupForm.checkSku === 'Y'
              ? v.outboundOrderData[i].scannedQuantity
              : v.outboundOrderData[i].quantity,
            packageGoodsId: v.outboundOrderData[i].packageGoodsId,
            mobileImieCodes: v.outboundOrderData[i].skuLists
          };
          data.push(obj);
        }
      }
      // 当不用校对sku、不录入包材、不称重时，不需要调称重接口
      if (type) {
        v.handerPackageData(v.originalData, 'noEntry');
      } else {
        let query = {
          packageId: v.packageId || null, // 包裹id,
          weight: v.formParameter.weight || 0, // 重量,
          weightUnit: v.packagingSetupForm.packingWeightUnit === 'g'
            ? 0
            : 1, // 重量单位 0：g 、1:kg, 默认g
          pickingGoodsSkuList: data
        };
        v.axios.put(api.put_wmsPicking_packingWeighting, query).then(res => {
          if (res.data.code === 0) {
            let talg = res.data.datas;
            v.handerPackageData(talg, 'yesEntry');
          }
        });
      }
    },

    // 扫描出库单，运单号，物流商单号时，需进行出库单状态校验，若为订单已取消状态的出库单，则弹窗提示，点击知道了弹窗消失，保持在本页面
    verifyIssue(identificationNumber) {
      let v = this;
      return new Promise(resolve => {
        v.axios.put(api.put_wmsPicking_checkScanPackaging + `${identificationNumber}?warehouseId=${v.getWarehouseId()}`).then(res => {
          if (res.data.code === 0) {
            let data = res.data.datas;
            if (data.length > 0) {
              v.$refs['orderCancelPrompt'].basicData = data;
              v.$refs['orderCancelPrompt'].orderCancelPromptModal = true;
            } else {
              resolve(true);
            }
          }
        });
      });
    },

    // 识别号、扫描SKU、扫描身份码、包材、称重 回车键
    identificationKey(key) {
      let v = this;
      // 识别号
      if (key === 1) {
        let identificationNumber = v.formParameter.identificationNumber;
        if (v.isSliceIdentificationNumber) {
          if (v.sliceStartLength) identificationNumber = identificationNumber.slice(v.sliceStartLength,);
          if (v.sliceEndLength) identificationNumber = identificationNumber.slice(0, identificationNumber.length - v.sliceEndLength);
        }
        if (identificationNumber !== '') {
          v.verifyIssue(identificationNumber).then(() => {
            if (v.getPermission('wmsPicking_scanPackaging')) {
              v.axios.put(api.put_wmsPicking_scanPackaging + `${identificationNumber}?warehouseId=${v.getWarehouseId()}`).then(res => {
                if (res.data.code === 0) {
                  let data = res.data.datas;
                  if (data.packageDetails && data.packageInfo) {
                    v.handerCheckSku();
                    v.deliveryOrder = data.packageInfo.packageCode;
                    v.packageId = data.packageInfo.packageId;
                    if (data.packageDetails.length > 0) {
                      data.packageDetails.map((item) => {
                        item['scannedQuantity'] = 0;
                        item['mobileImieList'] = [];
                      });
                      v.outboundOrderData = data.packageDetails;
                    }
                    v.originalData = data;
                    // 当不校对SKU、不录入包材、不称重时，扫描出库单时，就直接是扫描包裹成功了
                    if (v.packagingSetupForm.checkSku === 'N' && v.packagingSetupForm.packingEnableWeight === 'N') {
                      v.handerData(true);
                      return false;
                    }
                  } else {
                    v.$Message.error(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006016'));
                    return false;
                  }
                  // sku
                  if (v.packagingSetupForm.checkSku === 'Y') {
                    v.$nextTick(() => {
                      v.getFocus('scanSku');
                    });
                  }
                  // 称重
                  else if (v.packagingSetupForm.packingEnableWeight === 'Y') {
                    v.$nextTick(() => {
                      v.getFocus('weight');
                    });
                  }
                }
              });
            } else {
              v.gotoError();
            }
          });
        } else {
          v.$Message.error(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006017'));
        }
      }
      // 扫描SKU
      else if (key === 2) {
        let scanSku = v.formParameter.scanSku;
        v.signSku = scanSku;
        if (scanSku !== '') {
          let data = [];
          if (v.outboundOrderData.length > 0) {
            // 取出当前扫描sku的这一条数据
            v.outboundOrderData.map((item) => {
              if (item.sku === scanSku) {
                data.push(item);
              }
            });
            if (data.length > 0) {
              for (let i = 0; i < data.length; i++) {
                let item = data[i];
                if (item.quantity !== item.scannedQuantity) {
                  v.outboundOrderData.map((ele, index) => {
                    if (ele.sku === item.sku) {
                      ele.scannedQuantity += 1;
                      v.formParameter.scanSku = '';
                      if (ele.mobileImieList.length <= 0) {
                        for (let i = 0; i < item.quantity; i++) {
                          ele.mobileImieList.push({
                            index: i + 1,
                            sku: ele.sku,
                            value: ''
                          });
                        }
                        v.$set(v.outboundOrderData[index], 'mobileImieList', ele.mobileImieList);
                      }
                      // 当有检验身份码时，扫描sku后自动将光标定位到扫描身份码
                      if (v.packagingSetupForm.mobileImie === 'Y') {
                        v.$nextTick(() => {
                          v.getFocus('mobileImie');
                        });
                      }
                    }
                  });
                } else {
                  v.formParameter.scanSku = '';
                  v.$Message.error({
                    content: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006018'),
                    duration: 3
                  });
                  return false;
                }
              }
            } else {
              v.$Message.error({
                content: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006019'),
                duration: 3
              });
              return false;
            }
            let talgData = v.outboundOrderData.filter((item) => {
              return item.scannedQuantity === item.quantity;
            });
            // sku全部扫描后，移动焦点
            if (talgData.length === v.outboundOrderData.length) {
              if (v.packagingSetupForm.mobileImie === 'N' && v.packagingSetupForm.packingEnableWeight === 'N') {
                this.handerData();
              }
              // 身份码
              if (v.packagingSetupForm.mobileImie === 'Y') {
                v.$nextTick(() => {
                  v.getFocus('mobileImie');
                });
              }
              // 称重
              else if (v.packagingSetupForm.packingEnableWeight === 'Y') {
                v.$nextTick(() => {
                  v.getFocus('weight');
                });
              }
            }
          }
        } else {
          v.$Message.error(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006020'));
        }
      }
      // 身份码
      else if (key === 5) {
        // 自动填充身份码
        if (v.formParameter.mobileImie !== '') {
          v.outboundOrderData.map((item, index) => {
            if (item.mobileImieList.length > 0) {
              for (let i = 0; i < item.mobileImieList.length; i++) {
                let ele = item.mobileImieList[i];
                if (ele.sku === item.sku && ele.sku === v.signSku) {
                  if (ele.value === '') {
                    ele.value = v.formParameter.mobileImie;
                    v.$set(v.outboundOrderData[index].mobileImieList[i], 'value', v.formParameter.mobileImie);
                    break;
                  }
                }
              }
            }
          });
        }
        v.signSku = '';
        v.formParameter.mobileImie = '';
        v.getFocus('scanSku');
        let talgData = v.outboundOrderData.filter((item) => {
          return item.scannedQuantity === item.quantity;
        });
        if (talgData.length === v.outboundOrderData.length) {
          if (v.packagingSetupForm.packingEnableWeight === 'N') {
            this.handerData();
          }
          // 称重
          else if (v.packagingSetupForm.packingEnableWeight === 'Y') {
            v.$nextTick(() => {
              v.getFocus('weight');
            });
          }
        }
      }
      // 称重
      else if (key === 4) { // 当前称重 - 当前包裹的预估重量 >= 包装设置里面设置的包裹重量时，会出现称重异常提醒弹窗
        let weight = v.formParameter.weight;
        if (v.outboundOrderData.length > 0) {
          for (let i = 0; i < v.outboundOrderData.length; i++) {
            if (v.packagingSetupForm.checkSku === 'Y') {
              if (v.outboundOrderData[i].quantity !== v.outboundOrderData[i].scannedQuantity) {
                v.$Message.error(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006014'));
                return false;
              }
            }
          }
          if (weight !== '' && weight > 0) {
            // 扫描称重后校验包裹
            let query = {
              packageId: v.packageId,
              warehouseId: v.getWarehouseId(),
              unit: v.packagingSetupForm.packingWeightUnit,
              weight: weight
            };
            v.axios.put(api.put_packageInfo_validatePickingPackage, query).then((res) => {
              let data = res.data.datas;
              // 称重没有异常的时候，走正常的流程
              if (data.validate) {
                v.handerData();
              }
              // 称重有异常时，弹出称重异常提醒弹窗
              else {
                v.abnormalReminder = data;
                v.abnormalWeighingData = data.packageDetails;
                v.abnormalWeighing = true;
              }
            });
          } else {
            v.$Message.error(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006021'));
            return false;
          }
        } else {
          v.$Message.error(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006022'));
          return false;
        }
      }
    },
    // 监听包装设置弹窗
    packagingSetupChange(value) {
      if (!value) {
        this.disabledGroup = '0';
        this.disabled1Group = '0';
        this.packagingSetupForm = JSON.parse(JSON.stringify(this.originalSetupForm));
      }
    },
    // 获取包装设置的数据
    getDeliverySetting() {
      let v = this;
      if (v.getPermission('packageSettingCommon_queryPackingOperationsSetting')) {
        v.axios.get(api.get_OperationsSetting).then(res => {
          if (res.data.code === 0) {
            res.data.datas.forEach(i => {
              for (let key in v.packagingSetupForm) {
                if (i.paramKey === key) {
                  v.packagingSetupForm[key] = i.paramValue;
                }
              }
            });
            v.originalSetupForm = JSON.parse(JSON.stringify(v.packagingSetupForm));
            v.packagingSetupForm.packingSingleTranscend
              ? (v.disabledGroup = '0')
              : (v.disabledGroup = '1');
            v.packagingSetupForm.packingMultiTranscend
              ? (v.disabled1Group = '0')
              : (v.disabled1Group = '1');
            v.handerCheckSku();
            let talg = v.packagingSetupForm.packingWeightUnit === 'g'
              ? alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003127')
              : alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003128');
            v.weightTitle = alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003129') + talg + '(' + v.packagingSetupForm.packingWeightUnit + ')';
          }
        });
      } else {
        v.gotoError();
      }
    },
    // 处理包装设置的参数
    paramsHandel() {
      let v = this;
      let arr = [];
      for (let key in v.packagingSetupForm) {
        arr.push({
          paramKey: key,
          paramValue: v.packagingSetupForm[key]
        });
      }
      return arr;
    },
    // 包装设置弹窗的保存按钮
    packagingSetupBtn() {
      let v = this;
      let params = v.paramsHandel();
      v.axios.post(api.set_DeliverySetting, {paramBoList: params}).then(res => {
        if (res.data.code === 0) {
          v.$Message.success(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000102'));
          for (let key in v.packagingSetupForm) {
            v.packagingSetupForm[key] = '';
          }
          v.packagingSetup = false;
          v.getDeliverySetting();
        }
      });
    },
    // 添加校对列
    handerCheckSku() {
      let v = this;
      if (v.packagingSetupForm.checkSku === 'Y') {
        let checkSku = {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003844'),
          align: 'center',
          width: 100,
          key: 'proofreading',
          render: (h, params) => {
            let talg = params.row.scannedQuantity === params.row.quantity;
            return h('Icon', {
              props: {
                type: talg
                  ? 'md-checkmark'
                  : 'md-close',
                size: '24'
              },
              style: {
                fontWeight: 'bolder',
                color: talg
                  ? '#008000'
                  : '#FF0000'
              }
            });
          }
        };
        // 添加身份码列
        if (v.packagingSetupForm.mobileImie === 'Y') {
          let mobileImie = {
            title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005785'),
            align: 'center',
            key: 'mobileImieCode',
            render: (h, params) => {
              let obj = [];
              let data = params.row.mobileImieList;
              data.map((item, index) => {
                obj[index] = h('Input', {
                  props: {
                    value: item.value,
                    clearable: true
                  },
                  class: 'mobileImie_input',
                  on: {
                    input(val) {
                      v.outboundOrderData[params.index].mobileImieList[index].value = val;
                      v.$set(v.outboundOrderData[params.index].mobileImieList[index], 'value', val);
                    }
                  }
                });
              });
              return h('div', obj);
            }
          };
          v.outboundOrderColumn.map((item, index) => {
            if (item.key === 'mobileImieCode') {
              v.outboundOrderColumn.splice(index, 1);
            }
          });
          v.outboundOrderColumn.push(mobileImie);
        } else {
          v.outboundOrderColumn.map((item, index) => {
            if (item.key === 'mobileImieCode') {
              v.outboundOrderColumn.splice(index, 1);
            }
          });
        }
        v.outboundOrderColumn.map((item, index) => {
          if (item.key === 'proofreading') {
            v.outboundOrderColumn.splice(index, 1);
          }
        });
        v.outboundOrderColumn.push(checkSku);
      } else {
        v.outboundOrderColumn.map((item, index) => {
          if (item.key === 'proofreading') {
            v.outboundOrderColumn.splice(index, 1);
          }
        });
      }


    },
    // 继续包装
    continueDeliverGoods() {
      let v = this;
      v.abnormalWeighing = false;
      v.handerData();
    },
    // 取消包装
    unpackingBtn() {
      this.resetData();
    },
    // 标记异常
    markExceptionBtn() {
      let v = this;
      v.markExceptionData = JSON.parse(JSON.stringify(v.outboundOrderData));
      v.markException = true;
    },
    // 标记异常的确定按钮
    markExceptionSubmit() {
      let v = this;
      let data = [];
      let abnormalArr = [];
      if (v.markExceptionData.length > 0) {
        v.markExceptionData.map((item) => {
          if (item.quantity === item.scannedQuantity) {
            abnormalArr.push(item);
          }
          data.push({
            actualPickingNumber: item.scannedQuantity,
            packageGoodsId: item.packageGoodsId
          });
        });
        if (abnormalArr.length === v.markExceptionData.length) {
          v.$Message.error(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006023'));
          return false;
        }
        let query = {
          packageId: v.packageId,
          updateActualPickingNumBoList: data
        };
        v.axios.post(api.post_wmsPickingGoods_markException, query).then((res) => {
          if (res.data.datas) {
            v.$Message.success(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006024'));
            v.resetData();
          }
        });
      }
    },
    // 重置数据的公共方法
    resetData() {
      let v = this;
      v.abnormalWeighing = false;
      v.markException = false;
      v.markExceptionData = [];
      v.outboundOrderData = [];
      v.$refs['formParameter'].resetFields();
      v.getFocus('identificationNumber');
    },
    // 处理组装已扫描包裹列表的数据
    handerPackageData(data, type) {
      let v = this;
      let obj = {};
      if (data) {
        // 录入
        if (type === 'yesEntry') {
          obj = {
            packageCode: data.packageCode,
            trackingNumber: data.trackingNumber
          };
        }
        // 不录入
        else if (type === 'noEntry') {
          if (Object.keys(data).length > 0) {
            obj = {
              packageCode: data.packageInfo.packageCode,
              trackingNumber: data.packageInfo.trackingNumber
            };
          }
        }
        if (v.outboundOrderData.length > 0) {
          let talg = v.packagingSetupForm.packingWeightUnit === 'kg'
            ? 1000
            : 1; // 如果是kg 需乘1000
          let skuList = [];
          v.outboundOrderData.map((item) => {
            obj.weight = Number(v.formParameter.weight * talg).toFixed(2);
            skuList.push({
              sku: item.sku,
              quantity: item.quantity
            });
          });
          obj.skuList = skuList;
        }
        // 已包装列表只显示当前账号最近扫描的50个包裹
        v.packageData.unshift(obj);
      }
      if (v.packageData.length > 50) {
        v.packageData = v.packageData.slice(0, 50);
      }
      v.resetData();
      v.$Message.success(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006025'));
    },
    // 不勾选检验sku时, 去掉勾选身份码
    changeSku(value) {
      if (value === 'N') {
        if (this.packagingSetupForm.mobileImie === 'Y') {
          this.packagingSetupForm.mobileImie = 'N';
        }
      }
    },
    // 选中身份码时，自动勾选中校验SKU
    changeMobileImie(value) {
      if (value === 'Y') {
        this.packagingSetupForm.checkSku = 'Y';
      }
    }
  },
  components: {
    orderCancelPromptModal
  }
};
</script>

<style>
.ivu-tooltip-inner {
  width: 250px;
  white-space: normal;
}

.packaging_operation .ivu-input-number-small input {
  height: 24px !important;
  line-height: 24px !important;
}
</style>

<style lang="less" scoped>
.packaging_operation {
  padding-top: 10px;

  .operation_box {
    display: flex;
    justify-content: space-between;

    .card_box {
      width: 700px;
      height: max-content;

      /deep/ .ivu-card-head {
        border-bottom: none;
      }

      .title_box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
          color: #333;
          font-size: 16px;
          font-weight: bold;
        }

        .options {
          display: flex;
          align-items: center;

          .options_item {
            margin-left: 14px;

            /deep/ .ivu-tooltip-inner {
              max-width: 320px;
            }

            .txt {
              font-size: 15px;
              color: #666;
              margin-right: 5px;
            }

            .icon_yes {
              font-size: 20px;
              color: #008000;
              font-weight: bold;
            }

            .icon_no {
              font-size: 20px;
              color: #FF0000;
              font-weight: bold;
            }

            .help_icon {
              font-size: 21px;
              color: #999999;
              cursor: pointer;
            }

            .icon_set {
              font-size: 21px;
              color: #2D8CF0;
              cursor: pointer;
            }
          }
        }
      }

      /deep/ .ivu-card-body {
        background-color: #DBEBD3;

        .form_box /deep/ {
          padding-top: 15px;

          .ivu-form-item-label {
            font-size: 16px;
            padding-right: 0 !important;
            color: #333;

          }

          .ivu-select-selection {
            height: initial;
          }

          .ivu-select-arrow {
            display: none !important;
          }

          input {
            font-size: 14px;
            height: 38px;
            line-height: 38px;
          }
        }
      }
    }

    .outbound_order {
      width: 880px;
      height: max-content;

      /deep/ .ivu-card-head {
        padding: 8px 16px;
      }

      .outbound_tiem {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
          color: #333;
          font-size: 18px;
          font-weight: bold;
        }
      }

      /deep/ .mobileImie_input {
        .ivu-input {
          margin-top: 4px;
          height: 28px;
          line-height: 28px;
        }
      }

      /deep/ .mobileImie_input:last-child .ivu-input {
        margin-bottom: 4px;
      }
    }
  }

  .table_box {
    margin-top: 50px;

    .title {
      color: #333;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 15px;
    }
  }
}

.packagingSetupModal /deep/ {
  .modelTit {
    color: #333;
    font-size: 15px;
    font-weight: bold;
    border-bottom: 1px solid #CCCCCC;
    padding-bottom: 5px;
    margin-bottom: 15px;
  }

  .ivu-form-item {
    margin: 0;

    label {
      font-size: 14px;
    }
  }
}

.markExceptionModal /deep/ {
  .title {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
}

.abnormalWeighingModal /deep/ {
  .ivu-alert {
    margin-bottom: 20px;

    /deep/ .ivu-alert-message {
      font-size: 15px !important;
      line-height: 24px !important;
    }
  }

  .product_weight_box {
    margin-top: 25px;
    text-align: right;
    display: flex;
    flex-direction: column;

    .line_txt {
      color: #333;
      font-size: 16px;
      margin: 6px 0;

      .number {
        font-weight: bold;
      }
    }
  }
}

.w-40 {
  width: 40px;
}

.inline-block {
  display: inline-block
}
</style>
